.contact {
  background-size: cover;
  width: 100%;
  height: 100%;
  margin: 0;
  position: fixed;
}

.contactContainer {
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
  padding-top: 3em;
  padding-right: 1em;
  padding-left: 1em;
}

.subtitle {
  color: white;
}

#contact-methods {
  padding-top: 1em;
  display: flex;
  flex-direction: column;
  align-content: center;
  align-items: center;
}

#other-social {
  display: flex;
  gap: 1em;
  padding-top: 1em;
}

.contactMethod {
  display: flex;
  align-items: center;
  gap: 0.5em;
  padding: 0.3em;
}