#logo {
  padding-right: 1em;
}

#subtitle {
  padding-top: 0px;
  margin-top: 0px;
  font-weight: 200;
}

#title {
  padding-top: 0.5em;
}

#title-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

#navigation {
  padding-left: 2em;
}

@media (min-width: 600px) {
  #layeredBackground {
    width: 100%;
  }
}

#layeredBackground {
  position: absolute;
  z-index: -100;
  width: -webkit-fill-available;
}

.navButton {
  margin-right: 10em;
}

#top-bar {
  display: flex;
}

@media (max-width: 600px) {
  #top-bar {
    display: grid;
  }
}