.tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1em;
  gap: 1em;
}

.info {
  padding: 1em;
}
.images {
  padding-top: 1em;
  display: flex;
  gap: 1em;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
}
.regularImage {
  border-radius: 0.3em;
  object-fit: cover;
  max-height: 20em;
  max-width: 20em;
}
.linkIcon {
  padding-left: 0.5em;
}
