.tool {
  display: flex;
  background: #fff;
  border: 2px;
  border-radius: 25px;
  border-style: solid;
  padding: 10px;
  align-items: center;
  justify-content: center;
  width: 90px;
  height: 30px;
}

.toolImage {
  max-width: 90px;
}