.container {
  padding: 3em;
}

#my-image {
  width: 10em;
  border-radius: 0.3em;
}

.topRow {
  display: flex;
}

.imageBlock {
  padding-left: 5em;
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 600px) {
  .topRow {
    flex-direction: column;
  }

  .imageBlock {
    padding-left: 0em;
  }
}

.aboutText {
  line-height: 1.5;
}

#tools {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-top: 1em;
  gap: 1em;
}